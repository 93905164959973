<template>
  <div class="other-products text-center pt-106 pb-42">
    <h1 data-test="other-products-title" class="pb-90 text-42 text-grey-900">{{ title }}</h1>
    <other-products-items :cards="children" />
  </div>
</template>

<script>
export default {
  name: 'OtherProducts',
  components: {
    OtherProductsItems: () => import('@/components/OtherProductsItems.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
  },
}
</script>
